// import { React, useState} from 'react';
// import AspectRatio from '@mui/joy/AspectRatio';
// import Button from '@mui/joy/Button';
// import Card from '@mui/joy/Card';
// import CardContent from '@mui/joy/CardContent';
// import CardOverflow from '@mui/joy/CardOverflow';
// import Typography from '@mui/joy/Typography';
// import Checkbox from '@mui/material/Checkbox';
// import { styled } from '@mui/system';

// const WhiteCheckbox = styled(Checkbox)({
//     color: 'white',
//     '&.Mui-checked': {
//       color: 'white',
//     },
//   });
  

// export function ProductCard({ product, imageUrl }) {

//   const [checked, setChecked] = useState(false);

//   const handleButtonClick = () => {
//     setChecked(!checked);
//   };

//   let currency = 'U$S';
//   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

//   return (
//     <Card sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg' }}>
//       <CardOverflow>
//         <AspectRatio sx={{ minWidth: 200 }}>
//           <img
//             // src="https://images.unsplash.com/photo-1593121925328-369cc8459c08?auto=format&fit=crop&w=286"
//             src={imageUrl}
//             // srcSet="https://images.unsplash.com/photo-1593121925328-369cc8459c08?auto=format&fit=crop&w=286&dpr=2 2x"
//             // srcSet={product.imagen}
//             loading="lazy"
//             // alt={product?.imagen || 'Producto'}
//             alt={product?.descripcion || 'Producto'}
//           />
//         </AspectRatio>
//       </CardOverflow>
//       <CardContent>
//         <Typography level="body-xs" sx={{ mt: 1, fontWeight: 'xl' }}>
//           {/* {product.CodArticulo} */}
//           </Typography>
//         {/* <Link
//           href="#product-card"
//           fontWeight="md"
//           color="neutral"
//           textColor="text.primary"
//           overlay
//           endDecorator={<ArrowOutwardIcon />}
//         >
//           {product.Articulo}
//         </Link> */}

//         <Typography
//           level="title-lg"
//           sx={{ mt: 1, fontWeight: 'xl' }}
//         // endDecorator={
//         //   <Chip component="span" size="sm" variant="soft" color="danger">
//         //     Oferta
//         //   </Chip>
//         // }
//         >
//           {currency} 
//           {product.precio}
//         </Typography>
//         <Typography level="body-sm">
//           {/* (Solo <b>{product.Marcado}</b> en stock!) */}
//           {product.descripcion}
//         </Typography>
//       </CardContent>
//       <CardOverflow>
//         <Button variant="solid" size="lg" color="success" onClick={handleButtonClick}>
          
//           Agregar al pedido
//           {/* <Checkbox {...label} sx={{ left:"10%" }} checked={checked} 
//             onChange={(e) => setChecked(e.target.checked)}/> */}
//           <WhiteCheckbox 
//             {...label} 
//             sx={{ left: "10%" }} 
//             checked={checked} 
//             onChange={(e) => setChecked(e.target.checked)}
//           />
//         </Button>
//       </CardOverflow>
//     </Card>
//   );

//   // return (
//   //   <Card sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg' }}>
//   //       <CardOverflow>
//   //           <AspectRatio sx={{ minWidth: 200 }}>
//   //               <img
//   //                   src={imageUrl}
//   //                   loading="lazy"
//   //                   alt={product?.imagen || 'Producto'}
//   //               />
//   //           </AspectRatio>
//   //       </CardOverflow>
//   //       <CardContent>
//   //           <Typography level="body-xs" sx={{ mt: 1, fontWeight: 'xl' }}>
//   //           </Typography>
//   //           <Typography
//   //               level="title-lg"
//   //               sx={{ mt: 1, fontWeight: 'xl' }}
//   //           >
//   //               {currency} 
//   //               {product.precio}
//   //           </Typography>
//   //           <Typography level="body-sm">
//   //               {product.descripcion}
//   //           </Typography>
//   //       </CardContent>
//   //       <CardOverflow>
//   //           <Button variant="solid" size="lg" color="success" onClick={handleButtonClick}>
//   //               Agregar al pedido
//   //           </Button>
//   //           <WhiteCheckbox 
//   //           {...label} 
//   //           sx={{ left: "10%" }} 
//   //           checked={checked} 
//   //           onChange={(e) => setChecked(e.target.checked)}
//   //         />
//   //       </CardOverflow>
//   //   </Card>
// // );
// }

import React, { useState } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';

const WhiteCheckbox = styled(Checkbox)({
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
});

export function ProductCard({ product, imageUrl }) {
    const [checked, setChecked] = useState(false);

    const handleButtonClick = () => {
        setChecked(!checked);
    };

    let currency = 'U$S';
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <Card sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg' }}>
            <CardOverflow>
                <AspectRatio sx={{ minWidth: 200 }}>
                    <img
                        src={imageUrl}
                        loading="lazy"
                        alt={product?.descripcion || 'Producto'}
                    />
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                <Typography level="body-xs" sx={{ mt: 1, fontWeight: 'xl' }}></Typography>
                <Typography level="title-lg" sx={{ mt: 1, fontWeight: 'xl' }}>
                    {currency} {product.precio}
                </Typography>
                <Typography level="body-sm">
                    {product.descripcion}
                </Typography>
            </CardContent>
            <CardOverflow>
                <Button variant="solid" size="lg" color="success" onClick={handleButtonClick}>
                    Agregar al pedido
                    <WhiteCheckbox
                        {...label}
                        sx={{ left: "10%" }}
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                </Button>
            </CardOverflow>
        </Card>
    );
}
