import React from "react";
import { useState, useEffect } from "react";
import Header from "./headerContainer";
import Carrousel from "./carrousel";
import { Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import { ProductsCatalog } from '../components/ProductsCatalog';
import Typography from '@mui/joy/Typography';
import { Section } from "../components/Section";
import ContactFooter from "../components/ContactFooter";
import { ShopNavigator } from '../components/ShopNavigator/ShopNavigator';
import { fetchAllFamilies } from "../Macrosoft_API";
import useMainContext from "../components/Context/useMainContext";


export const Index = () => {
    const { testContext,
        setSectionSelected,
        sectionSelected
    } = useMainContext()
    const sectionTexts = ["DAHUA", "Domotica", "Ofertas"];

    const [articles, setArticles] = useState([]);
    const [product, setProduct] = useState([])
    const [categories, setCategories] = useState([])
    // const [optionShop, setoptionShop] = useState()
    const handleGetArticles = async () => {
        try {
            const response = await fetchAllFamilies()
            console.log('respuesta desde el fetch:', response)
            if (response?.data) {
                setArticles(response.data)
                console.log('ES ESTE FETCH:', response.data)
            }
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    useEffect(() => {
        console.log('desde context:', testContext)
        handleGetArticles()
    }, [])

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Header
                        
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '4rem' }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBottom: '15rem' }}>
                    {
                        sectionSelected == 'ShopNavigator' && (
                            <ShopNavigator
                            />)
                    }
                    {
                        sectionSelected == 'Carrousel' && (
                            <Carrousel
                                setSectionSelected={setSectionSelected}
                            />
                        )
                    }
                    {/* <Carrousel /> */}
                    {/* <ShopNavigator/> */}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} spacing={15} sx={{ marginTop: '25%' }}>
                    {sectionTexts.map((text, index) => (
                        <Grid key={index} item xs={12}>
                            <Typography variant="solid" size="lg" color="danger">{text}</Typography>
                            <ProductsCatalog />
                        </Grid>
                    ))}
                </Grid> */}
                <Grid item xs={12} sx={{ mt: '10rem' }}>
                    {/* <Typography level="h2" sx={{  fontWeight: 'xl' }}>¡Productos Destacados!</Typography> */}
                    <Typography variant="h1" sx={{ color: 'black', fontSize: '2.5rem' }}>¡Productos Destacados!</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}
                    sx={{
                        color: 'black',
                        py: 4,
                        mt: '5%',
                        boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom',
                        marginTop: '2rem'
                    }}>
                    {/* <Typography variant="solid" size="lg" color="danger">{sectionTexts[0]}</Typography> */}
                    <Typography level="h4" color="danger" sx={{ mt: 1, fontWeight: 'xl' }}>{sectionTexts[0]}</Typography>
                    <ProductsCatalog search="DAHUA" listaPrecio="1" />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Section />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ContactFooter />
                </Grid>
            </Grid>
        </>
    )

}