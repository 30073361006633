import Typography from '@mui/joy/Typography';
import { Grid } from "@mui/material";
import CctvCamera from '../erebil/Assets/cctv-camera.svg';
import People from '../erebil/Assets/people.svg';
import Lock from '../erebil/Assets/lock.svg';
import Light from  '../erebil/Assets/light.svg';
import MonitorCode from '../erebil/Assets/monitor.svg';
import World from '../erebil/Assets/world.svg';

export const Section = () => {

    return (
        <>

        <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '80%', margin: '0 auto' }}>
                <Typography variant="h1" sx={{ color: 'black', margin: '1rem 0', fontSize: '2.5rem' }}>¿Qué podemos ofrecerle?</Typography>
                <Typography variant="p" sx={{ color: 'black', fontSize: '1.1rem' }}>
                    Proveemos, múltiples soluciones que se ajustan a cada necesidad y presupuesto. Contamos con experiencia certificada, en los Sistemas de Seguridad Electrónica, de las marcas más reconocidas a nivel internacional, como son: Checkpoint, Pelco, Dahua, Alpha, Se-kure, HID y CAS entre otras.
                </Typography>
            </Grid>
            <Grid container className='items' spacing={2} sx={{ marginTop: '2rem', marginLeft: '6%' }} alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                        <img src={Lock} alt="cctv camera" style={{ width: 40, height: 40, marginRight: '1rem' }} />
                        <Grid sx={{ marginLeft: '0.7rem' }}>
                            <Grid variant="h1">Chekpoint RF/RFID</Grid>
                            <Typography>Seguridad Electrónica Anti Hurto</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                        <img src={CctvCamera} alt="cctv camera" style={{ width: 40, height: 40, marginRight: '1rem' }} />
                        <Grid sx={{ marginLeft: '1rem' }}>
                            <Grid variant="h2">DAHUA</Grid>
                            <Typography variant="p">Cctv Video Vigilancia</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                        <img src={People} alt="people" style={{ width: 40, height: 40, marginRight: '1rem' }} />
                        <Grid sx={{ marginLeft: '4rem' }}>
                            <Grid variant="h2">Control</Grid>
                            <Typography variant="p" sx={{ fontSize: "1rem" }}>Control de acceso</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center" >
                    <img src={Light} alt="cctv camera" style={{ width: 40, height: 40, marginRight: '1rem' }} />
                        <Grid sx={{ marginLeft: '1.5rem' }}>
                            <Grid variant="h2">Promolux</Grid>
                            <Typography variant="p">Iluminación Comercial LED</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                    <img src={MonitorCode} alt="people" style={{ width: 40, height: 40, marginRight: '1rem' }} />
                        <Grid sx={{ marginLeft: '1.3rem' }}>
                            <Grid variant="h2">Software/Hardware</Grid>
                            <Typography variant="p">Puntos de Venta</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container alignItems="center">
                    <img src={World} alt="people" style={{ width: 40, height: 40, marginRight: '1rem' }} />
                        <Grid>
                            <Grid variant="h2">Consultoría</Grid>
                            <Typography variant="p">Proyectos de trazabilidad y Logística</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}