import * as React from 'react';
import { useState } from 'react';
import { Input, Box, Button, Grid, FormControl, InputLabel, FormHelperText, Divider, TextField, Select, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../../erebil/Assets/stylesFormRegister.css'
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:'10px'
};

export default function LoginModal({ openModalLogin, setopenModalLogin }) {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const handleOpen = () => setOpen(true);
  const handleClose = () => setopenModalLogin(false);
  const handleLogin = async () => {
    console.log('se ejecuta login')
    const data = {
      user: username, pass: password
    }

  }
  const [typeList, setTypeList] = useState('Seleccione tipo de Usuario')
  return (
    <div>
      <Modal
        open={openModalLogin}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid className='headerFormRegister'>
              <h2 class='tituloFormRegister'>
                INICIAR SESION
              </h2>
            </Grid>
            <Grid className='containerFormRegister'>
              <Grid className='containerInputForm' >
                <TextField
                  id="campo1"
                  label="Username"
                  value={username}
                  onChange={(e) => { setUsername(e.target.value) }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="campo2"
                  label="Password"
                  type='password'
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  fullWidth
                  margin="normal"
                />

              </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Grid sx={{margin:2}}>
                <Button
                  onClick={handleLogin}
                  variant='outlined'>
                  Iniciar
                </Button>
              </Grid>
              <Grid>
                <Button onClick={handleClose} variant='outlined'>
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Box>
      </Modal>
    </div>
  );
}
