// import {React, useState, useEffect} from "react";
// import { Grid } from "@mui/material";
// import { ProductCard } from '../components/ProductCard';
// // import { fetchExternalDataGetGroupDahua } from '../Macrosoft_API/communication';
// // import { fetchAllArticles } from "../Macrosoft_API";
// import { fetchArticlesByFilter } from '../Macrosoft_API';
// import { fetchImagesByArticle } from "./controller/imageController";
// // import axios from 'axios';
// // const apiUrl = process.env.REACT_APP_API_URL;

// export const ProductsCatalog = ({ search, listaPrecio, page = 1  }) => {

//     const [products, setProducts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [images, setImages] = useState([]);


//     useEffect(() => {
//       const fetchData = async () => {
//           try {

//               const data = await fetchArticlesByFilter({search, listaPrecio, page});
//               console.log('Data fetched:', data);
//               setProducts(data);
//               const imagesFetched = await fetchImagesByArticle(products.CodArticulo);
//                 // const imagesPromises = data.map(product =>  fetchImagesByArticle(product.CodArticulo));
//               console.log("CODIGO DE ARTICULO ACA: ", imagesFetched);
//                 // // const imagesData = await Promise.all(imagesPromises);

//                 // const imagesMap = imagesData.reduce((associatedImage, imageData, index) => {
//                 //     associatedImage[data[index].CodArticulo] = imageData;
//                 //     return associatedImage;
//                 // }, {});


//                 setImages(imagesFetched);
//                 setLoading(false);
//           } catch (error) {
//               setError(error);
//               setLoading(false);
//           }
//       };

//       fetchData();
//     }, [search, listaPrecio, page]);
//       // const fetchImages = async () => {
//       //   try {
//           // const response = await fetch('http://localhost:5000/list'); // Adjust the endpoint as necessary
//         //   const urls = await response.json();
//         //   setImageUrls(urls);
//         //   console.log("Imaagenes fetched ok here!", response, urls);
//         // } catch (error) {
//         //   console.error('Error fetching images:', error);
//         // }
//       // };
//       //**************************************************************************** */
// //       const fetchImages = async () => {
// //         try {
// //             // const response = await axios.get(`${apiUrl}/getImagesByArticle`, {
// //             //             params: { CodArticulo: search }
// //             //           });
// //             const response = await axios.get('http://localhost:5000/getImagesByArticle?CodArticulo=DAH622', {
// //                 params: { CodArticulo: search }
// //             });
// //             // const response = await axios.get(`${apiUrl}/getImagesByArticle?CodArticulo=${search}`);
// //             console.log("Images fetched:", response.data);
// //             setImages(response.data);
// //         } catch (error) {
// //             console.error('Error fetching images:', error);
// //         }
// //     };

// //       fetchImages();
// //       fetchData();
// //   }, [search, listaPrecio, page]);

//     //**************************************************************************** */

// //   const handleGetProductCImage = async (search) => {
// //         console.log('Codigo articulo', search);
// //         try {
// //             const params = {
// //                 search: search,
// //             }
// //             const response = await fetchImages(params)
    
// //             console.log('respuesta desde el fetch:', response)

// //             // if (response?.data) {
// //             //     // setProduct(response.data)
// //             //     console.log('Respuesta de obtener los productos', response.data)
// //             // }
// //         } catch (error) {
// //             console.log('hubo un error en el fetch:', error)
// //         }
// //     }


//     if (loading) {
//       console.log('Token vencido');
//       return <div>Lo sentimos, no llegamos al dato...</div>
//     };

//     if (error) return <div>Error cargando productos: {error.message}</div>;

//     const firstFiveProducts = products.slice(0, 5);

//   //   return (
//   //     <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
//   //       {firstFiveProducts.map((product, index) => (
//   //         <Grid item key={index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', justifyContent: 'center', marginLeft: '1%' }}>
//   //           <ProductCard product={product} />
//   //         </Grid>
//   //       ))}
//   //     </Grid>
//   // );

// //   return (
// //     <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
// //         {firstFiveProducts.map((product, index) => {
// //             const image = images.find(img => img.CodArticulo === product.CodArticulo);
// //             const imageUrl = image ? image.urlImage : 'default-image-url.jpg'; // Provide a default image URL
// //             return (
// //                 <Grid item key={index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', justifyContent: 'center', marginLeft: '1%' }}>
// //                     <ProductCard product={product} imageUrl={imageUrl} />
// //                 </Grid>
// //             );
// //         })}
// //     </Grid>
// // );

// return (
//     <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
//         {firstFiveProducts.map((product, index) => {
//             const image = images[product.CodArticulo];
//             const imageUrl = image ? image.urlImage : 'default-image-url.jpg'; // Provide a default image URL
//             return (
//                 <Grid item key={index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', justifyContent: 'center', marginLeft: '1%' }}>
//                     <ProductCard product={product} imageUrl={imageUrl} />
//                 </Grid>
//             );
//         })}
//     </Grid>
// );

// }

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ProductCard } from '../components/ProductCard';
import { fetchArticlesByFilter } from '../Macrosoft_API';
import { fetchImagesByArticle } from './controller/imageController';

export const ProductsCatalog = ({ search, listaPrecio, page = 1 }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [images, setImages] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchArticlesByFilter({ search, listaPrecio, page });
                setProducts(data);

                const imagesPromises = data.map(async (product) => {
                    const imagesFetched = await fetchImagesByArticle(product.CodArticulo);
                    return { CodArticulo: product.CodArticulo, urlImage: imagesFetched[0]?.urlImage || 'default-image-url.jpg' };
                });

                const imagesData = await Promise.all(imagesPromises);
                const imagesMap = imagesData.reduce((acc, image) => {
                    acc[image.CodArticulo] = image.urlImage;
                    return acc;
                }, {});

                setImages(imagesMap);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [search, listaPrecio, page]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading products: {error.message}</div>;

    const firstFiveProducts = products.slice(0, 5);

    return (
        <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {firstFiveProducts.map((product, index) => {
                const imageUrl = images[product.CodArticulo] || 'default-image-url.jpg';
                return (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', justifyContent: 'center', marginLeft: '1%' }}>
                        <ProductCard product={product} imageUrl={imageUrl} />
                    </Grid>
                );
            })}
        </Grid>
    );
};
