import { Box, Container, Grid, Typography, Link, Snackbar  } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelePhone from '../../src/erebil/Assets/telephone.svg';
import MobilePhone from '../../src/erebil/Assets/phone.svg';
import Company from '../../src/erebil/Assets/company.svg';
import Mail from '../../src/erebil/Assets/mail_2.svg';
import footerShapeImage from '../erebil/Assets/footer_shape.png';
import {React , useState} from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


const ContactFooter = () => {

  const [alertOpen, setAlertOpen] = useState(false);

  const copyEmailToClipboard = async () => {
    try {
      await navigator.clipboard.writeText('ventas@erebil.com.uy');
      setAlertOpen(true);
    } catch (err) {
      console.error('Failed to copy email:', err);
      setAlertOpen(true);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Box
        component="footer"
        sx={{
          color: 'black',
          py: 1,
          mt: 4,
          boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
          backgroundImage: `url(${footerShapeImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Erebil</Typography>
            </Grid>
            <Grid item>
              <Grid container sx={{ display: 'flex', ml: '7rem' }} spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Link href="https://www.instagram.com/erebil.sa?igsh=MWh4d25zcGV2YnE4ag==" target="_blank" rel="noopener" color="inherit" underline="none">
                    <InstagramIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)' }} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://www.facebook.com/share/artpr1i2z4js3rBG/?mibextid=LQQJ4d" target="_blank" rel="noopener" color="inherit" underline="none">
                    <FacebookIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)' }} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://api.whatsapp.com/send?phone=59899669721" target="_blank" rel="noopener" color="inherit" underline="none">
                    <WhatsAppIcon fontSize="small" sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)' }} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center" justifyContent="space-between" marginTop={'0.1%'}>
            <Link href="https://maps.app.goo.gl/miGDhJvC7jpJaabr6" target="_blank" rel="noopener" color="inherit" underline="none">
              <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)' }}>
                <img src={Company} alt="company" style={{ width: 30, height: 30, marginRight: '0.5rem' }} />
                <Typography variant="body1">Juan Paulllier 1178</Typography>
              </Grid>
            </Link>

            <Grid sx={{ mt:'2%', ml:'10%', padding:'-2%' }}>
            <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '1%', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)', marginBottom: '2rem', marginTop: '-3rem' }}>
                <Typography variant="body1" >
                  <Grid container alignItems="center">
                    <img src={TelePhone} alt="telephone" style={{ width: 30, height: 30, marginRight: '1rem' }} />
                    <Grid sx={{ color: '#ff0000' }}>+598 2409 9877</Grid>
                  </Grid>
                </Typography>
              </Grid>
            
            
            
            <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '4px solid #ffff', borderRadius: '5px', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="body1">
                <Grid display="flex" alignItems="center">
                  <img src={MobilePhone} alt="mail" style={{ width: 30, height: 30, marginRight: '0.5rem' }} />
                  <Grid rel="noopener" color="inherit" underline="none" sx={{ color: '#ff0000' }}>
                    +598 099 669 721
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            </Grid>
            
          
              
            
                
              

            
            <Grid item sx={{ backgroundColor: '#ffff', color: '#ff0000', border: '3px solid #ffff', borderRadius: '5px', padding: '2px', boxShadow: '0 10px 5px rgba(0, 0, 0, 0.1)', cursor: 'pointer' }}>
              <Typography variant="body1">
                <Grid display="flex" alignItems="center" onClick={copyEmailToClipboard}>
                  <img src={Mail} alt="mail" style={{ width: 30, height: 30, marginRight: '0.5rem' }} />
                  <Link rel="noopener" color="inherit" underline="none" sx={{ color: '#ff0000' }}>
                    ventas@erebil.com.uy
                  </Link>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
          <Grid mt={'1rem'} ml={'3rem'}>
            <Typography variant="caption" align="center" mt={1} sx={{ bgcolor: 'grey.800', color: 'white', display: 'inline-block', padding: '2px 4px', borderRadius: '2px' }} marginTop={'1%'}>
              Copyright 2024 - Erebil S.A. by JAR Software
            </Typography>
          </Grid>
        </Container>
      </Box>
       <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ display: 'flex', margin: '0 auto', left: '1rem' }} // Position below the email element
     >
        <Alert onClose={handleAlertClose} severity="success" sx={{
      position: 'relative', // Change to relative
      left: '-85%', // Adjust to move left
      top: '50%',
      transform: 'translateX(-50%)', // Centering trick
    }} icon={<CheckIcon fontSize="inherit" />}>
          Email copiado!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactFooter;
