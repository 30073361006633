import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { MenuLat } from './MenuLat';
import { ShopMain } from './ShopMain';
export const ShopNavigator = () => {

    return (
        <>
            <Grid container sx={{ height: '100%', width: '100vw', border: '2px solid black' }}>
                <Grid item md={2}>
                    <MenuLat />
                </Grid>
                <Grid item md={9}>
                    <ShopMain />
                </Grid>
            </Grid>
        </>
    )
}