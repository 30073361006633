import * as React from 'react';
import { useState } from 'react';
// import { Grid, TextField, Button, Stack } from '@mui/material';
import { Grid, TextField, Stack } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './Assets/logo.png'
// import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { Select, MenuItem, Menu } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import PopupState, { bindTrigger, bindMenu, bindHover } from 'material-ui-popup-state';
import perfilIcon from './Assets/perfil.png'
import carritoIcon from './Assets/carrito-de-compras.png'
import RegisterModal from '../components/modalRegister'
import LoginModal from '../components/Login/modalLogin'
import useMainContext from '../components/Context/useMainContext';
const theme = createTheme({
    palette: {
        primary: {
            main: '#E21B1B', // Define tu color principal aquí
        },
    },
});
export default function HeaderPrincipal() {
    const [openModalRegister, setopenModalRegister] = useState(false)
    const [openModalLogin, setopenModalLogin] = useState(false)
    const {
        setSectionSelected,
        sectionSelected } = useMainContext()
    return (
        <ThemeProvider theme={theme}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', marginLeft: '5%' }}>
                {/* Logo */}
                <Grid item xs={6} sm={6} md={2}>
                    <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => { setSectionSelected('Carrousel') }}
                        width={'100%'}
                        src={logo}
                        alt={'Erebil'}
                        loading="lazy"
                        height={'120rem'}
                    />
                </Grid>

                {/* Iconos (Solo en xs) */}
                <Grid item xs={12} sm={6} md={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <ShoppingCartIcon sx={{ fontSize: '3rem', margin: '1rem' }} />
                    <AccountCircleIcon sx={{ fontSize: '3rem', margin: '1rem' }} />
                </Grid>

                {/* Campo de búsqueda */}
                <Grid item xs={12} sm={6} md={7}>
                    <TextField
                        sx={{ width: '80%', marginTop: '2rem' }}
                        label="Buscador.."
                    />
                </Grid>

                {/* Iconos (No en xs) */}
                <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between', marginTop: '1rem', }}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                        <div class='containerForPerfil'>
                            <div class='item'>
                                <button onClick={() => { setopenModalRegister(true) }} class='botonRegistrarme pulse-button'>
                                    REGISTRARME
                                </button>
                            </div>
                            <div class='item iconHover'>
                                <svg class="iconTransform" width="3rem" height="3rem">
                                    <image href={carritoIcon} width="100%" height="100%" />
                                </svg>
                            </div>
                            <div class='item iconHover'>
                                <svg class="iconTransform" width="3rem" height="3rem">
                                    <image href={perfilIcon}
                                        onClick={() => { setopenModalLogin(true) }}
                                        width="100%" height="100%" />
                                </svg>
                                <div>
                                    {/* <a href="#" class="menu-link textoHover">OFERTAS</a> */}
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Iniciar Sesion</a></li>
                                        {/* <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Stack>
                </Grid>
            </Grid>
            <RegisterModal
                openModalRegister={openModalRegister}
                setopenModalRegister={setopenModalRegister}
            />
            <LoginModal
                openModalLogin={openModalLogin}
                setopenModalLogin={setopenModalLogin}
            />
        </ThemeProvider>
    );
}
