import axios from 'axios';

export const fetchImagesByArticle = async (articleCode) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const params = new URLSearchParams();
    params.append('CodArticulo', articleCode);

    try {
        const response = await axios.get(`${apiUrl}/getImagesByArticle`, { params });
        console.log("Images fetched:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching images:', error);
        throw error;
    }
};
