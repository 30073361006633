import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export const getToken = async () => {
    const data = {
        user: 'macrosoft-erebil',
        pass: 'macr-e83Bil.9911'
    }
    try {
        const response = await axios.post(`${apiUrl}/login`, data, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer YOUR_TOKEN_HERE',
            }
        })
        console.log('respuesta del token : ', response)
        return response.data.token
    } catch (error) {
        console.log('hubo un error:', error)
        throw error
    }
}

// add fetchAllClients

export const fetchAllFamilies = async () => {
    //PRIMERA FAMILIA
    const token = await getToken()
    const params = new URLSearchParams()
    params.append('token', token)
    const url = `${apiUrl}/getAllFamilies?${params}`
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}

//change to fetchAllSubFamilies
export const fetchSubCategories = async (categorie) => {
    //SEGUNDA FAMILIA
    const token = await getToken()
    console.log('categoria recibida', categorie)
    const params = new URLSearchParams()
    params.append('token', token)
    // params.append('page', page)
    params.append('subFamily', categorie.categories)
    const url = `${apiUrl}/getAllSubFamilies?${params}`
    console.log('Url:', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer YOUR_TOKEN_HERE',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}
export const fetchAllSubCategoryByThirdFamily = async (data) => {
    const { subFamily, family, page } = data
    const token = await getToken()
    const params = new URLSearchParams()
    params.append('token', token)
    params.append('page', page)
    params.append('subFamily', subFamily)
    params.append('family', family)
    const url = `${apiUrl}/getSubCategoryByThirdFamily?${params}`
    console.log('url->', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer YOUR_TOKEN_HERE',
            }
        })
        console.log('respuesta fetch : ', response);
        return response.data;
    } catch (error) {
        console.log('hubo un error:', error)
        throw error;
    }
}

// Obtener get con todos los filtros   
export const fetchArticles = async ({ description, family, group, CodCliente, CodStock }) => {

    const token = await getToken()
    const params = new URLSearchParams()

    params.append('token', token)
    params.append('description', description)
    params.append('family', family)
    params.append('group', group)
    params.append('CodCliente', CodCliente)
    params.append('CodStock', CodStock)

    const url = `${apiUrl}/getArticles?${params}`;
    console.log('ruta desde el front:', url)
    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        // console.log('respuesta fetch : ', response);
        console.log('Response from fetchArticles:', response.data);
        return response.data.data;
    } catch (error) {
        console.log('hubo un error:', error);
        throw error;
    }
};

// add fetchArticleByFilter
export const fetchArticlesByFilter = async ({ search, listaPrecio, page = 1 }) => {
    // const {search, listaPrecio, page } = data
    const token = await getToken()
    const params = new URLSearchParams()
    params.append('token', token)
    params.append('search', search)
    params.append('listaPrecio', listaPrecio)
    params.append('page', page)


    const url = `${apiUrl}/getArticleByFilter?${params.toString()}`;

    try {
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        // console.log('respuesta fetch : ', response);
        console.log('Response from fetchArticlesByFilter:', response.data);
        return response.data.data;
    } catch (error) {
        console.log('hubo un error:', error);
        throw error;
    }
};

//add fetchAllListPrices