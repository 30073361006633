import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CarrouselMUI from './carrouselMUI'
import { ShopNavigator } from '../components/ShopNavigator/ShopNavigator';

export default function Carrousel() {
    return (
        <Box sx={{ flexGrow: 1, height: 100 }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'center', paddingX: '2rem' }}>
                <Grid item>
                    {/* <ShopNavigator /> */}
                    <CarrouselMUI />
                </Grid>
            </Grid>
        </Box>
    );
}
