  import * as React from 'react';
  import { useState, useEffect } from 'react';
  import Box from '@mui/material/Box';
  import List from '@mui/material/List';
  import ListItemButton from '@mui/material/ListItemButton';
  import ListItemIcon from '@mui/material/ListItemIcon';
  import ListItemText from '@mui/material/ListItemText';
  import Collapse from '@mui/material/Collapse';
  import Divider from '@mui/material/Divider';
  import ArrowRightIcon from '@mui/icons-material/ArrowRight';
  import ExpandLess from '@mui/icons-material/ExpandLess';
  import ExpandMore from '@mui/icons-material/ExpandMore';
  import useMainContext from '../Context/useMainContext';
  import { fetchAllSubCategoryByThirdFamily, fetchArticles } from '../../Macrosoft_API';
  export function MenuLat() {
    const [openItems, setOpenItems] = useState({});
    const { subCategories,
      groups,
      setGroups,
      categories,
      setSectionSelected,
      articles,
      setArticles,

    } = useMainContext();
    // const [localGroup, setLocalGroup] = useState(0)
    // const [ref, setRef] = useState(0)
    useEffect(() => {
      console.log('data recibida en menu lat:', subCategories);
    }, [subCategories]);

    const handleClick = (family, subFamily) => {
      console.log('itemID', subFamily)
      setOpenItems(prev => ({ ...prev, [subFamily]: !prev[subFamily] }));
      handleGetGroups(family, subFamily)
    };
    const handleGetGroups = async (family, subFamily) => {
      setSectionSelected('ShopNavigator')
      console.log('funciona el icon')
      try {
        const params = {
          subFamily: subFamily,
          family: categories,
          listaPrecio: '1',
          page: '1'
        }
        console.log('params', params)
        const response = await fetchAllSubCategoryByThirdFamily(params)
        console.log('Respuesta de obtener los grupos', response)
        if (response?.data) {
          setGroups(response.data)
        }
        return response.data
      } catch (error) {
        console.log('hubo un error en el fetch:', error)
      }
    }
    const handleGetArticles = async (subFamily,CodStock) => {
      console.log('funciona el click')
      try {
        const params = {
          subFamily: subFamily,
          family: categories,
          CodStock:CodStock,
          CodCliente: '101',
          page: '1'
        }
        console.log('params', params)
        const response = await fetchArticles(params)
        console.log('Respuesta de obtener los articulos', response)
        if (response?.data) {
          setArticles(response.data)
        }
        return response.data
      } catch (error) {
        console.log('hubo un error en el fetch:', error)
      }
    }
    return (
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <List component="nav" aria-label="main categories">
          {subCategories?.map(item => (
            <React.Fragment key={item.Grupo}>
              {console.log('Item en MenuLat:', item)}
              <ListItemButton onClick={() => handleClick(item.ref, item.Grupo)}>
                <ListItemIcon>
                  {openItems[item.Grupo] ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
                <ListItemText primary={item.Descripcion} />
              </ListItemButton>
              <Collapse in={openItems[item.Grupo]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* Aquí irían las subcategorías. Por ahora, pongo un ejemplo: */}
                  {
                    groups?.map(Element => (

                      <ListItemButton
                        onClick={()=>{handleGetArticles(item.Grupo,Element.CodStock)}}
                        sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ArrowRightIcon />
                        </ListItemIcon>
                        <ListItemText primary={Element.Articulo} />
                      </ListItemButton>
                    ))
                  }


                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
        <Divider />
      </Box>
    );
  }