import * as React from 'react';
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Select, Menu, MenuItem, IconButton, Typography, Button } from '@mui/material';
import styles from './Assets/styles.css'
import Box from '@mui/material/Box';
import NavLateral from '../dashboard/NavLateral';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import LockIcon from '@mui/icons-material/Lock';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import BuildIcon from '@mui/icons-material/Build';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import VideocamIcon from '@mui/icons-material/Videocam';
import PopupState, { bindTrigger, bindMenu, bindHover } from 'material-ui-popup-state';
import monitorPng from './Assets/monitor.png'
import iconoCamara from './Assets/iconcam.png'
import vocero from './Assets/vocero.png'
import domotica from './Assets/domotica.png'
import pregunta from './Assets/pregunta.png'
import alarmas from './Assets/alarma.png'
import oferta from './Assets/venta.png'
import { fetchAllSubCategoryByThirdFamily, fetchSubCategories, fetchArticles } from '../Macrosoft_API';
import useMainContext from '../components/Context/useMainContext';
// Define el tema personalizado con un color principal personalizado
const theme = createTheme({
    palette: {
        primary: {
            main: '#ff0000', // Define tu color principal aquí
        },
    },
});

export default function Categories() {
    const {
        categories,
        setCategories,
        subCategories,
        setSubCategories,
        groups,
        setGroups,
        articles,
        setArticles,
        setSectionSelected,
        sectionSelected
    } = useMainContext()
    const testIcon = () => {
        console.log('test icon')
    }
    const handleGetGroups = async (categorie, group) => {
        setSectionSelected('ShopNavigator')
        console.log('funciona el icon')
        try {
            const params = {
                subFamily: categorie,
                group: group,
                listaPrecio: '1',
                page: '1'
            }
            const response = await fetchAllSubCategoryByThirdFamily(params)

            // console.log('respuesta desde el fetch:', response)
            if (response?.data) {
                setGroups(response.data)
                console.log('Respuesta de obtener los productos', response.data)
            }
            return response.data
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    const handleGetSubCategories = async (categorie) => {
        try {
            const params = {
                categories: categorie,
                listaPrecio: '1',
                page: '1'
            }
            const response = await fetchSubCategories(params)
            if (response?.data) {
                setSubCategories(response.data)
                return response.data
            }
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    const handleGetArticles = async () => {
        console.log('funciona get Articles ')
        try {
            const params = {
                description: 'DAHUA',
                CodStock: '-',
                CodCliente: '101',
                family: '-',
                group: '-',
                page: '1'
            }
            const response = await fetchArticles(params)

            console.log('respuesta de obtener los articulos:', response)
            if (response) {
                setArticles(response)
                return response
            }
        } catch (error) {
            console.log('hubo un error en el fetch:', error)
        }
    }
    const handleGetAllData = async (categorie, subcategorie) => {
        setCategories(subcategorie)
        const subcategories = await handleGetSubCategories(subcategorie)
        const productCategorie = await handleGetGroups(subcategorie, categorie)
        console.log('SUB CATEGORIES DESDE EL HANDLE:--->', subcategories)
        console.log(' productCategorie DESDE EL HANDLE:--->', productCategorie)
        handleGetArticles()
    }
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100%' }}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                    <nav>
                        <ul class="menu">
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('15.1', 15) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={iconoCamara} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">DAHUA</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg
                                    onClick={() => { handleGetAllData('AUDIO ITC', 14) }}
                                    class="iconTransform" width="7rem" height="7rem">
                                    <image href={vocero} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">AUDIO</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg class="iconTransform" width="7rem" height="7rem">
                                    <image href={domotica} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">DOMOTICA</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg class="iconTransform" width="7rem" height="7rem">
                                    <image href={pregunta} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">INTRUSIAN</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg class="iconTransform" width="7rem" height="7rem">
                                    <image href={monitorPng} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">DISPLAY</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg class="iconTransform" width="7rem" height="7rem">
                                    <image href={alarmas} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">ALARMAS COMERCIALES</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                            {/*----- CATEGORIA ------*/}
                            <li class="menu-item iconHover">
                                <svg class="iconTransform" width="7rem" height="7rem">
                                    <image href={oferta} width="100%" height="100%" />
                                </svg>
                                <div>
                                    <a href="#" class="menu-link textoHover">OFERTAS</a>
                                    <ul class="submenu">
                                        <li><a class="textoHover" href="#">Antenas</a></li>
                                        <li><a class="textoHover" href="#">Cables y adaptadores</a></li>
                                        <li><a class="textoHover" href="#">Cámaras digitales</a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </Grid>

            </Box>
        </ThemeProvider>
    );
}
